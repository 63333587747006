// Copyright 2016 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Directive for the miscellaneous tab in the admin panel.
 */
require('domain/utilities/UrlInterpolationService.ts');
require('pages/admin-page/services/admin-task-manager.service.ts');
require('pages/admin-page/admin-page.constants.ts');
var oppia = require('AppInit.ts').module;
oppia.directive('adminMiscTab', [
    '$http', '$window', 'AdminTaskManagerService', 'UrlInterpolationService',
    'ADMIN_HANDLER_URL', 'ADMIN_TOPICS_CSV_DOWNLOAD_HANDLER_URL',
    function ($http, $window, AdminTaskManagerService, UrlInterpolationService, ADMIN_HANDLER_URL, ADMIN_TOPICS_CSV_DOWNLOAD_HANDLER_URL) {
        return {
            restrict: 'E',
            scope: {},
            bindToController: {
                setStatusMessage: '='
            },
            templateUrl: UrlInterpolationService.getDirectiveTemplateUrl('/pages/admin-page/misc-tab/admin-misc-tab.directive.html'),
            controllerAs: '$ctrl',
            controller: [function () {
                    var ctrl = this;
                    var DATA_EXTRACTION_QUERY_HANDLER_URL = ('/explorationdataextractionhandler');
                    ctrl.clearSearchIndex = function () {
                        if (AdminTaskManagerService.isTaskRunning()) {
                            return;
                        }
                        if (!$window.confirm('This action is irreversible. Are you sure?')) {
                            return;
                        }
                        ctrl.setStatusMessage('Clearing search index...');
                        AdminTaskManagerService.startTask();
                        $http.post(ADMIN_HANDLER_URL, {
                            action: 'clear_search_index'
                        }).then(function () {
                            ctrl.setStatusMessage('Index successfully cleared.');
                            AdminTaskManagerService.finishTask();
                        }, function (errorResponse) {
                            ctrl.setStatusMessage('Server error: ' + errorResponse.data.error);
                            AdminTaskManagerService.finishTask();
                        });
                    };
                    ctrl.uploadTopicSimilaritiesFile = function () {
                        var file = document.getElementById('topicSimilaritiesFile').files[0];
                        var reader = new FileReader();
                        reader.onload = function (e) {
                            var data = e.target.result;
                            $http.post(ADMIN_HANDLER_URL, {
                                action: 'upload_topic_similarities',
                                data: data
                            }).then(function () {
                                ctrl.setStatusMessage('Topic similarities uploaded successfully.');
                            }, function (errorResponse) {
                                ctrl.setStatusMessage('Server error: ' + errorResponse.data.error);
                            });
                        };
                        reader.readAsText(file);
                    };
                    ctrl.downloadTopicSimilaritiesFile = function () {
                        $window.location.href = ADMIN_TOPICS_CSV_DOWNLOAD_HANDLER_URL;
                    };
                    var setDataExtractionQueryStatusMessage = function (message) {
                        ctrl.showDataExtractionQueryStatus = true;
                        ctrl.dataExtractionQueryStatusMessage = message;
                    };
                    ctrl.submitQuery = function () {
                        var STATUS_PENDING = ('Data extraction query has been submitted. Please wait.');
                        var STATUS_FINISHED = 'Loading the extracted data ...';
                        var STATUS_FAILED = 'Error, ';
                        setDataExtractionQueryStatusMessage(STATUS_PENDING);
                        var downloadUrl = DATA_EXTRACTION_QUERY_HANDLER_URL + '?';
                        downloadUrl += 'exp_id=' + encodeURIComponent(ctrl.expId);
                        downloadUrl += '&exp_version=' + encodeURIComponent(ctrl.expVersion);
                        downloadUrl += '&state_name=' + encodeURIComponent(ctrl.stateName);
                        downloadUrl += '&num_answers=' + encodeURIComponent(ctrl.numAnswers);
                        $window.open(downloadUrl);
                    };
                    ctrl.resetForm = function () {
                        ctrl.expId = '';
                        ctrl.expVersion = 0;
                        ctrl.stateName = '';
                        ctrl.numAnswers = 0;
                        ctrl.showDataExtractionQueryStatus = false;
                    };
                }]
        };
    }
]);
